import React from "react";
import "./App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import HomeScreen from "./pages/HomeScreen";
import usePreventZoom from "./function/usePreventZoom";

function App() {
    usePreventZoom()

    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomeScreen />} />
            </Routes>
        </Router>
    );
}

export default App;