import React, { useEffect, useRef, useState } from 'react'
import '../style/HomeScreen.css'
import '../style/Artisnya.css'
import Line from '../components/CustomLine';
import { useMediaQuery } from 'react-responsive'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { Link } from 'react-router-dom';
import Carousel from '../function/Carousel';
import DisableZoom from '../function/DisableZoom';

export default function HomeScreen() {
    const [pages, setPages] = useState('')
    const parallax = useRef();
    const ref = useRef(null);
    const top = useRef(null)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, [])

    useEffect(() => {
        parallaxPagesHandler()
    }, [pages])

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        console.log('scrollPosition', scrollPosition);
    };

    const isLargeDevice = useMediaQuery({query: '(max-width: 840px'})
    const isSmallDevice = useMediaQuery({query: '(max-width: 450px'})

    const midFull = require('../assets/Home/mid-full.png')
    const leftTop = require('../assets/Home/cikini.png')
    const leftBot = require('../assets/Home/roman.png')
    const rightTop = require('../assets/Home/rasa.png')
    const rightBot = require('../assets/Home/mumet.png')

    const arrowRight = require('../assets/Home/arrowRight.png')
    const konsersium = require('../assets/Home/konsersium.png')

    const twitter = require('../assets/Home/twitter.png')
    const tiktok = require('../assets/Home/tiktok.png')
    const youtube = require('../assets/Home/youtube.png')
    const spotify = require('../assets/Home/spotify.png')
    const ig = require('../assets/Home/instagram.png')

    const photo1 = require('../assets/img/tentang/ig1.png')
    const photo2 = require('../assets/img/tentang/ig2.png')
    const photo3 = require('../assets/img/tentang/ig3.png')
    const photo4 = require('../assets/img/tentang/ig4.png')

    const bintangWhite = require('../assets/bintang-white.png')

    const icon = require('../assets/Header/iconHeader.png')
    const ytBorder = require('../assets/img/tentang/yt-border.png')

    const artisnya = require('../assets/img/artisnya/artisnya.png')
    const oteweTitle = require('../assets/img/otewe/Otw-title.png')
    const depok = require('../assets/img/otewe/depok.png')
    const bekasi = require('../assets/img/otewe/bekasi.png')
    const bogor = require('../assets/img/otewe/bogor.png')
    const tangerang = require('../assets/img/otewe/tangerang.png')

    const dagangan = require('../assets/img/dagangan/dagangan.png')
    const berita = require('../assets/img/berita/berita.png')

    const tiketHabis = require('../assets/Home/tiket-habis.png')
    const cekLink = require('../assets/Home/cek-link.png')
    const regularOne = require('../assets/Home/175.000.png')
    const regularTwo = require('../assets/Home/300.000.png')
    const tiketnyaSis = require('../assets/Home/tiketnya-sis.png')
    const beliDisini = require('../assets/Home/beliDisini.png')
    const tunaiPay = require('../assets/Home/tunaiPay.png')
    const dewaTiket = require('../assets/Home/dewaTiket.png')

    const daftarArtis = require('../assets/Artis/daftar-artis.png')
    const kingNasar = require('../assets/Artis/nasar-jkt.png')
    const happyAsmara = require('../assets/Artis/happy-asmara.png')
    const gildCoustic = require('../assets/Artis/gildcoustic.png')
    const tuan13 = require('../assets/Artis/tuan13.png')
    const ndarBoy = require('../assets/Artis/ndarboy.png')
    const cacaHandika = require('../assets/Artis/caca-handika.png')
    const salsaBintan = require('../assets/Artis/salsa-bintan.png')
    const abelKokom = require('../assets/Artis/abdel-kokom.png')
    const banyakan = require('../assets/Artis/banyakan.png')
    const banyakan1 = require('../assets/Artis/banyakan1.png')

    const sponsor = require('../assets/Home/sponsor.png')
    const bgFooter = require('../assets/Home/bg-footer.png')

    const handleClick = (value) => {
        if(value === 'Tentang') {
            setPages('')
            setTimeout(() => {
                ref.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: "center"
                });
            }, 100)
            return
        }
        ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: "center"
        });
        return
    };

    const handleClickTop = () => {
        top.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: "center"
        });
        return
    };

    const pageHandler = (route) => {
        setPages(route)
    }

    const parallaxPagesHandler = () => {
        if(isLargeDevice) return 2.18
        if(isSmallDevice) return 2.18
        if(pages === 'Artisnya') return 0.5
        return 3.8
    }

    return (
        <div>
            <DisableZoom />
            <div className='main-container'>
                <Parallax pages={parallaxPagesHandler()} className='parallax image-bg-container' ref={parallax}>
                    {isSmallDevice ? (
                        <div ref={top}>
                            <nav className='navbar-mobile'>
                                <div className='nav-container-mobile flex'>
                                    <img src={icon} className={'nav-logo-hp'} alt='logo' onClick={() => pageHandler('')}/>
                                    <div className='nav-elements-mobile-hp flex'>
                                        <button onClick={() =>pageHandler('Artisnya')} className='text-xs'>
                                            Artisnya
                                        </button>
                                        <button onClick={() =>pageHandler('Otewe')} className='text-xs'>
                                            Jadwal OTEWE
                                        </button>
                                        {/* <button onClick={() => handleClick('Tentang')} className='text-xs'>
                                            Tentang Kita
                                        </button> */}
                                    </div>
                                </div>
                            </nav>
                            <ParallaxLayer offset={isLargeDevice ? 0.1 : 0.2} speed={1.5}>
                                <img src={bintangWhite} alt='bintang' className='bintang-bg-container-hp'/>
                                <img src={bintangWhite} alt='bintang' className='bintang-bg-container-hp'/>
                                <img src={bintangWhite} alt='bintang' className='bintang-bg-container-hp'/>
                                <img src={bintangWhite} alt='bintang' className='bintang-bg-container-hp'/>
                                <img src={bintangWhite} alt='bintang' className='bintang-bg-container-hp'/>
                            </ParallaxLayer>
                            <ParallaxLayer offset={0.1} speed={0.9}>
                                {pages === '' && (
                                    <div>
                                        <div>
                                            <div className='cek-tiket-hp'>
                                                <Link onClick={() => window.location.href = 'https://linktr.ee/semestabergoyang?utm_source=linktree_profile_share&ltsid=085fa56e-cce2-4109-abf5-5096e6227898'}>
                                                    <p className='nav-cek-tiket-mobile-hp text-sm'>Cek Tiket</p>
                                                </Link>
                                            </div>
                                            <div className=''>
                                                <div className='flex logo-wrapper'>
                                                    <div className='flex flex-col'>
                                                        <img src={leftTop} alt='left-top-hp' className='left-top-hp'/>
                                                        {/* <img src={leftBot} alt='left-bot-hp' className='left-bot-hp'/> */}
                                                    </div>
                                                    <div className={isLargeDevice ? 'image-mid-mobile' : 'image-mid'}>
                                                        <img src={midFull} alt='midFull' className='mid-hp'/>
                                                    </div>
                                                    <div className='flex flex-col'>
                                                        <img src={rightTop} alt='right-top-hp' className='right-top-hp'/>
                                                        {/* <img src={rightBot} alt='right-top-hp' className='right-bot-hp' /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Line /> */}
                                            <div className='flex flex-col p-5'>
                                                <div className='flex flex-row justify-around align-middle'>
                                                    <img src={rightBot} alt='right-top-hp' style={{ width: '17%', height: '10%'}} />
                                                    {/* <img src={tiketHabis} alt='tiketHabis' style={{ width: '50%'}} /> */}
                                                    <img src={cekLink} alt='cekLink' style={{ width: '40%'}} />
                                                </div>
                                                <div className='flex justify-center align-middle flex-col'>
                                                    <img src={beliDisini} alt='beliDisini' style={{ width: '55vw', height: '20vh'}} className='self-center mr-10' />
                                                    <div className='flex-col absolute mt-10 self-center justify-center left-[37vw] align-middle'>
                                                        <img onClick={() => window.location.href ='https://dewatiket.id/semesta-bergoyang-jilid-2'} src={dewaTiket}  alt='dewaTiket' style={{ width: '30vw'}} className='mb-1' />
                                                        <img onClick={() => window.location.href ='https://etupay.tunaipay.com/event/semesta-bergoyang-jilid2'} src={tunaiPay} alt='tunaiPay' style={{ width: '30vw'}} />
                                                    </div>
                                                </div>
                                                {/* <div className='flex flex-row justify-center mt-10 mb-10'>
                                                    <img src={regularOne} alt='regularOne' style={{ width: '30%', position: 'relative', top: -20}} />
                                                    <img src={regularTwo} alt='regularTwo' style={{ width: '30%'}} className='ml-3' />
                                                </div> */}
                                                <div className='flex flex-row justify-between mt-10'>
                                                    <img src={tiketnyaSis} alt='tiketnyaSis' style={{width: '40vw', height: '20vh'}}/>
                                                    <img src={leftBot} alt='left-bot-hp' style={{width: '20vw', height: '10vh'}} className='mt-5'/>
                                                </div>
                                            </div>
                                            <Line />
                                            <div ref={ref} className='flex flex-col justify-center items-center'>
                                                <h1 className='text-lg mt-10' style={{color: 'black'}}>Tentang Semesta Bergoyang</h1>
                                                <div className='border-bottom-mobile-hp' />
                                                <div className='tentang-container-mobile-hp'>
                                                    <div className='text-content-hp'>
                                                        <p style={{color: 'black', marginBottom: 3, fontSize: 10}}>Festival Musik Dangdut cross genre pertama di Indonesia yang mengkolaborasikan musik dangdut dengan jenis musik lain.</p>
                                                        <p style={{color: 'black', fontSize: 10}}>Sehingga, selain musik dangdut, audiens pun akan mendapatkan pengalaman spesial yaitu menyaksikan musisi dangdut favorit mereka berkolaborasi dengan musisi favorit mereka dari genre lain.</p>
                                                    </div>
                                                </div>
                                                <Carousel />
                                                <div className='flex grid-cols-2 gap-2 photo-tentang-container ml-5 mr-5' style={{marginTop: 20}}>
                                                    <img className='flex-1 w-40 h-[20vh]' alt='photo4' src={photo4} onClick={() => window.location.href = 'https://www.instagram.com/semestabergoyang/'} />
                                                    <div className='flex-1' style={{position: 'relative'}}>
                                                        <iframe className={isLargeDevice ? 'yt-video-mobile z-0' : 'h-60 flex-1 yt-video'}  src="https://www.youtube.com/embed/uxebCvlTsjM?si=CNMYhiPT68v56tDI" title="Semesta Bergoyang Official" allowFullScreen></iframe>
                                                        <img src={ytBorder} className={isLargeDevice ? 'yt-border-mobile z-10' : 'yt-border'}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-10 bg-white m-10 rounded-3xl'>
                                                <img src={sponsor} alt='sponsor' className='p-5'/>
                                                {/* <Line />
                                                    <div className='flex justify-center items-center py-2'>
                                                        <p style={{color: 'black'}}>Cek Info Lainnya </p>
                                                        <img src={arrowRight} alt='arrow-right' className='arrow-hp ml-5'/>
                                                    </div>
                                                <Line /> */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {pages === 'Artisnya' && (
                                    <div className='mt-10 flex flex-col p-5'>
                                        <div className='flex flex-col justify-center'>
                                            <img src={daftarArtis} alt='daftarArtis' className='scale-75'/>
                                            <img src={kingNasar} alt='kingNasar' className='scale-50 relative bottom-20'/>
                                        </div>
                                        <div className='relative bottom-20'>
                                            <div className='flex flex-row justify-around relative bottom-10'>
                                                <img src={happyAsmara} alt='happyAsmara' style={{width: '40%', height: '40%'}} />
                                                <img src={gildCoustic} alt='gildCoustic' style={{width: '40%', height: '40%'}} />
                                            </div>
                                            <div className='flex flex-row justify-evenly align-middle'>
                                                <img src={abelKokom} alt='abelKokom' style={{width: '40%', height: '40%'}}/>
                                            </div>
                                            {/* <div className='flex flex-row justify-center mt-10 relative bottom-10'>
                                                <img src={tuan13} alt='tuan13' style={{width: '55%', height: '90%'}} />
                                                <div className='flex-auto w-64 relative bottom-5'>
                                                    <img src={ndarBoy} alt='ndarBoy' style={{width: '90%', height: '50%'}} />
                                                    <img src={cacaHandika} alt='cacaHandika' style={{width: '100%', height: '65%'}} className='relative right-5 top-2' />
                                                </div>
                                            </div> */}
                                            {/* <div className='flex flex-row justify-around'>
                                                <img src={salsaBintan} alt='salsaBintan' style={{width: '50%', height: '80%'}} className='relative bottom-20'/>
                                                <img src={abelKokom} alt='abelKokom' style={{width: '40%', height: '40%'}} className='relative bottom-3'/>
                                            </div> */}
                                            <div className='flex flex-row'>
                                                <img src={banyakan1} alt='banyakan1' style={{width: '100%', height: '80%'}}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {pages === 'Otewe' && (
                                    <div className='otewe-container mt-10'>
                                        <img src={oteweTitle} className='otewe'/>
                                        <img src={depok} className='kota-left'/>
                                        <img src={bogor} className='kota-right'/>
                                        <img src={bekasi} className='kota-left' />
                                        <img src={tangerang} className='kota-right' />
                                    </div>
                                )}
                                {pages === 'Dagangan' && (
                                    <div ref={ref} className='aj-center'>
                                        <img src={dagangan} className='dagangan-img-hp'/>
                                    </div>
                                )}
                                {pages === 'Berita' && (
                                    <div ref={ref} className='aj-center'>
                                        <img src={berita} className='berita-img-hp'/>
                                    </div>
                                )}
                            </ParallaxLayer>
                            <div id='tentang' className='footer-hp px-5 py-5'>
                                <div className='flex justify-between items-center'>
                                    <div>
                                        <ul>
                                            <li onClick={() => {
                                                pageHandler('Berita')
                                                handleClickTop()
                                                }} style={{color: 'black'}} className='text-sm'>Berita</li>
                                            <li onClick={() => {
                                                pageHandler('Dagangan')
                                                handleClickTop()
                                                }} style={{color: 'black'}} className='text-sm'>Toko Dagangan</li>
                                            <li onClick={() => window.location.href ='https://wa.me/+62082312348339'} style={{color: 'black'}} className='text-sm'>Hubungi Kami</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <img src={konsersium} className='w-20'/>
                                    </div>
                                </div>
                                <div className='flex items-center justify-between pt-5'>
                                    <p className='copy-hp' style={{color: 'black'}}>© Copyright 2023 Semesta Bergoyang All Right Reserved</p>
                                    <div className='flex'>
                                        <img className='w-6 h-6' src={spotify} onClick={() => window.location.href = 'https://open.spotify.com/user/31uu56xrso72kbwzioediignpjo4?si=4c80d242005c4dbb&nd=1&dlsi=6d44c105d2154c23'}/>
                                        <img className='w-6 h-6 mx-1' src={tiktok} onClick={() => window.location.href = 'https://www.tiktok.com/@semestabergoyang'}/>
                                        <img className='w-6 h-6' src={youtube} onClick={() => window.location.href = 'https://www.youtube.com/@semestabergoyang'}/>
                                        <img className='w-6 h-6 ml-1' src={ig} onClick={() => window.location.href = 'https://www.instagram.com/semestabergoyang/'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ( 
                        <div ref={top}>
                            <div>
                                <nav className={isLargeDevice ? 'navbar-mobile' : "navbar"}>
                                    <div className={isLargeDevice ? 'nav-container-mobile flex' : "nav-container flex"}>
                                        <img src={icon} className={isLargeDevice ? 'nav-logo-mobile' : 'scale-75' } alt='logo' onClick={() => pageHandler('')}/>
                                        <div className={isLargeDevice ? 'nav-elements-mobile flex' : "nav-elements flex"}>
                                            <button onClick={() =>pageHandler('Artisnya')} className={isLargeDevice ? 'text-lg' : 'text-2xl'}>
                                                Artisnya
                                            </button>
                                            <button onClick={() =>pageHandler('Otewe')} className={isLargeDevice ? 'text-lg' : 'text-2xl'}>
                                                Jadwal OTEWE
                                            </button>
                                            <button onClick={() => handleClick('Tentang')} className={isLargeDevice ? 'text-lg' : 'text-2xl'}>
                                                Tentang Kita
                                            </button>
                                        </div>
                                            <Link onClick={() => window.location.href = 'https://linktr.ee/semestabergoyang?utm_source=linktree_profile_share&ltsid=085fa56e-cce2-4109-abf5-5096e6227898'}>
                                                <p className={isLargeDevice ? 'nav-cek-tiket-mobile text-lg' : 'nav-cek-tiket text-2xl'}>Cek Tiket</p>
                                            </Link>
                                    </div>
                                </nav>
                            </div>
                            <ParallaxLayer offset={isLargeDevice ? 0.1 : 0.2} speed={1.5}>
                                <img src={bintangWhite} alt='bintang' className='bintang-bg-container' />
                            </ParallaxLayer>
                            <ParallaxLayer offset={0.2} speed={1}>
                                {pages === '' && (
                                    <div>
                                        <div className={isLargeDevice ? 'container-home-mobile' : 'container-home'}>
                                            <div className='flex logo-wrapper relative bottom-20'>
                                                {(!isLargeDevice) && (
                                                    <div className='image-left'>
                                                        <img src={leftTop} alt='leftTop' className='scale-[0.8] relative left-40 bottom-33'/>
                                                        {/* <img src={leftBot} alt='leftBot' className='scale-[0.8] relative left-24'/> */}
                                                    </div>
                                                )}
                                                <div className={isLargeDevice ? 'image-mid-mobile' : 'image-mid'}>
                                                    <img src={midFull} alt='midFull' className={isLargeDevice ? 'mid-mobile' : 'mid scale-75'} />
                                                </div>
                                                {!isLargeDevice && (
                                                    <div className='image-right'>
                                                        <img src={rightTop} alt='rightTop' className='scale-[0.8] relative right-40'/>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* <Line /> */}
                                        <div className='mt-20 pl-10 pr-10'>
                                            <div className='flex flex-row justify-around'>
                                                <img src={rightBot} alt='rightBot' style={{ width: '12%', height: '12%'}}/>
                                                <img src={cekLink} alt='cekLink' style={{ width: '30%'}}/>
                                            </div>
                                            <div className='flex justify-center flex-col'>
                                                {/* <img src={tiketHabis} alt='tiketHabis' style={{ width: '25%'}} className='relative scale-[1]' /> */}
                                                <img src={beliDisini} alt='beliDisini' className='justify-center self-center mt-20 mr-28' style={{ width: '50vw', height: '60vh' }}/>
                                                <div className='flex-col justify-center self-center absolute left-[40vw] mt-60'>
                                                    <img onClick={() => window.location.href ='https://dewatiket.id/semesta-bergoyang-jilid-2'} src={dewaTiket}  alt='dewaTiket' style={{ width: '23vw', height: '9vh' }} className='mb-5' />
                                                    <img onClick={() => window.location.href ='https://etupay.tunaipay.com/event/semesta-bergoyang-jilid2'} src={tunaiPay} alt='tunaiPay' style={{ width: '23vw', height: '9vh' }} />
                                                </div>
                                            </div>
                                            {/* <div className='flex flex-row justify-center mt-60 mb-36'>
                                                <img src={regularOne} alt='regularOne' style={{ width: '30%', position: 'relative', top: -100}} />
                                                <img src={regularTwo} alt='regularTwo' style={{ width: '30%'}} className='ml-3' />
                                            </div> */}
                                            <div className='flex flex-row justify-around mt-20'>
                                                <>
                                                    <img src={tiketnyaSis} alt='tiketnyaSis' style={{ width: '25%' }} />
                                                    <img src={leftBot} alt='leftBot' style={{ width: '12%', height: '12%' }} />
                                                </>
                                            </div>
                                        </div>
                                        <Line />
                                        <div id='tentang' ref={ref} className='container-tentang'>
                                            <h1 className={isLargeDevice ? 'text-lg mt-20' : 'text-3xl mt-20'} style={{color: 'black'}}>Tentang Semesta Bergoyang</h1>
                                            <h1 className={isLargeDevice ? 'border-bottom-mobile' : 'border-bottom'} />
                                            <div className={isLargeDevice ? 'tentang-container-mobile' : 'tentang-container'}>
                                                <div className='tentang-text'>
                                                    <div className='text-contain' style={{marginTop: isLargeDevice && -100}}>
                                                        <p className={isLargeDevice ? 'text-sm text-tetang' : 'text-lg text-tetang'} style={{color: 'black', marginBottom: 10}}>Festival Musik Dangdut cross genre pertama di Indonesia yang mengkolaborasikan musik dangdut dengan jenis musik lain.</p>
                                                        <p className={isLargeDevice ? 'text-sm text-tetang' : 'text-lg text-tetang'} style={{marginTop: isLargeDevice ? 20 : 20, color: 'black'}}>Sehingga, selain musik dangdut, audiens pun akan mendapatkan pengalaman spesial yaitu menyaksikan musisi dangdut favorit mereka berkolaborasi dengan musisi favorit mereka dari genre lain.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={isLargeDevice ? 'galery-container-mobile' : 'galery-container'}>
                                                <div className='grid grid-cols-3 gap-3'>
                                                    <img alt='photo1' src={photo1} className={'flex-auto w-64'} onClick={() => window.location.href = 'https://www.instagram.com/semestabergoyang/'} />
                                                    <img alt='photo2' src={photo2} className={'flex-auto w-64'} onClick={() => window.location.href = 'https://www.instagram.com/semestabergoyang/'} />
                                                    <img alt='photo3' src={photo3} className={'flex-auto w-64'} onClick={() => window.location.href = 'https://www.instagram.com/semestabergoyang/'} />
                                                </div>
                                                <div className='flex grid-cols-2 gap-2 photo-tentang-container' style={{marginTop: 20}}>
                                                    <img className='flex-1 w-32' alt='photo4' src={photo4} onClick={() => window.location.href = 'https://www.instagram.com/semestabergoyang/'} />
                                                    <div className='flex-1' style={{position: 'relative'}}>
                                                        <iframe className={isLargeDevice ? 'yt-video-mobile' : 'h-60 flex-1 yt-video'}  src="https://www.youtube.com/embed/uxebCvlTsjM?si=CNMYhiPT68v56tDI" title="Semesta Bergoyang Official" allowFullScreen></iframe>
                                                        <img src={ytBorder} className={isLargeDevice ? 'yt-border-mobile' : 'yt-border'}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex mt-20 bg-white m-56 rounded-3xl'>
                                            <img src={sponsor} alt='sponsor' className='p-5'/>
                                            {/* <Line />
                                                <div className='flex justify-center items-center py-2'>
                                                    <p style={{color: 'black'}}>Cek Info Lainnya </p>
                                                    <img src={arrowRight} alt='arrow-right' className='arrow-hp ml-5'/>
                                                </div>
                                            <Line /> */}
                                        </div>
                                    </div>
                                )}
                                {pages === 'Artisnya' && (
                                    <div ref={ref} className='artisnya-container'>
                                        <div className='mt-10 flex flex-col p-5 justify-center align-middle'>
                                            <div className='flex flex-col justify-center align-middle'>
                                                <img src={daftarArtis} alt='daftarArtis' className='scale-75'/>
                                                <img src={kingNasar} alt='kingNasar' style={{width: '35%'}} className='justify-center self-center relative bottom-32'/>
                                            </div>
                                            <div className='flex flex-col justify-center align-middle relative bottom-24'>
                                                <div className='flex flex-row justify-evenly align-middle'>
                                                    <img src={happyAsmara} alt='happyAsmara' style={{width: '30%'}}/>
                                                    <img src={gildCoustic} alt='gildCoustic' style={{width: '30%'}}/>
                                                </div>
                                                <div className='flex flex-row justify-evenly align-middle'>
                                                    <img src={abelKokom} alt='abelKokom' style={{width: '30%', height: '20%'}}/>
                                                </div>
                                                {/* <div className='flex flex-row justify-evenly align-middle mt-10'>
                                                    <img src={tuan13} alt='tuan13' style={{width: '40%'}} />
                                                    <img src={ndarBoy} alt='ndarBoy' style={{width: '30%', height: '20%'}} />
                                                </div>
                                                <div className='flex flex-row justify-center align-middle mt-10'>
                                                    <img src={salsaBintan} alt='tuan13' style={{width: '40%', height: '20%'}} />
                                                    <img src={cacaHandika} alt='cacaHandika' style={{width: '35%', height: '50%'}} className='relative bottom-52' />
                                                    <img src={abelKokom} alt='abelKokom' style={{width: '30%', height: '20%'}} className='absolute right-64 -bottom-10'/>
                                                </div> */}
                                                {/* <div className='flex flex-row relative bottom-36'>
                                                    <img src={tuan13} alt='tuan13' className='' style={{width: '32%', height: '30%'}} />
                                                    <div className='flex-auto w-64 relative bottom-8'>
                                                        <img src={ndarBoy} alt='ndarBoy' style={{width: '32%', height: '30%'}}/>
                                                        <img src={cacaHandika} alt='cacaHandika' style={{width: '32%', height: '30%'}}/>
                                                    </div>
                                                </div>
                                                <div className='flex flex-row justify-around'>
                                                    <img src={salsaBintan} alt='salsaBintan' style={{width: '50%', height: '80%'}} className='relative bottom-20'/>
                                                    <img src={abelKokom} alt='abelKokom' style={{width: '40%', height: '40%'}} className='relative'/>
                                                </div>
                                                <div className='flex flex-row'>
                                                    <img src={banyakan} alt='banyakan' style={{width: '100%', height: '80%'}}/>
                                                </div>
                                                 */}
                                            </div>
                                            <div className='flex flex-row justify-center align-middle'>
                                                <img src={banyakan1} alt='banyakan1' style={{width: '80%', height: '60%'}}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {pages === 'Otewe' && (
                                    <div className='otewe-container'>
                                        <img src={oteweTitle} className='otewe'/>
                                        <img src={depok} className='kota-left'/>
                                        <img src={bogor} className='kota-right'/>
                                        <img src={bekasi} className='kota-left' />
                                        <img src={tangerang} className='kota-right' />
                                    </div>
                                )}
                                {pages === 'Dagangan' && (
                                    <div ref={ref} className='aj-center'>
                                        <img src={dagangan} className='dagangan-img'/>
                                    </div>
                                )}
                                {pages === 'Berita' && (
                                    <div ref={ref} className='aj-center'>
                                        <img src={berita} className='berita-img'/>
                                    </div>
                                )}
                            </ParallaxLayer>
                            {/* <ParallaxLayer sticky={{start: isLargeDevice ? 0.9 : 2.2, end: isLargeDevice ? 0.3 : 2}} speed={1} className='footer-parallax'>
                                <div className={isLargeDevice ? 'footer-mobile' : 'footer'}>
                                    <div className={'footer-news'}>
                                        <div>
                                            <ul>
                                                <li onClick={() => {
                                                    pageHandler('Berita')
                                                    handleClickTop()
                                                    }} style={{color: 'black'}}>Berita</li>
                                                <li onClick={() => {
                                                    pageHandler('Dagangan')
                                                    handleClickTop()
                                                    }} style={{color: 'black'}}>Toko Dagangan</li>
                                                <li onClick={() => window.location.href ='https://wa.me/+62082312348339'} style={{color: 'black'}}>Hubungi Kami</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <img src={konsersium} className='konsersium'/>
                                        </div>
                                    </div>
                                    <div className='copyright mt-5'>
                                        <p className={isLargeDevice ? 'copy-text-mobile' : 'copy-text'} style={{color: 'black'}}>© Copyright 2023 Semesta Bergoyang All Right Reserved</p>
                                        <div className='flex'>
                                            <img className='logo-footer' src={spotify} style={{marginRight: 30}} onClick={() => window.location.href = 'https://open.spotify.com/user/31uu56xrso72kbwzioediignpjo4?si=4c80d242005c4dbb&nd=1&dlsi=6d44c105d2154c23'}/>
                                            <img className='logo-footer' src={tiktok} style={{marginRight: 30}} onClick={() => window.location.href = 'https://www.tiktok.com/@semestabergoyang'}/>
                                            <img className='logo-footer' src={youtube}style={{marginRight: 30}} onClick={() => window.location.href = 'https://www.youtube.com/@semestabergoyang'}/>
                                            <img className='logo-footer' src={ig} onClick={() => window.location.href = 'https://www.instagram.com/semestabergoyang/'}/>
                                        </div>
                                    </div>
                                </div>
                            </ParallaxLayer>       */}
                            <div className='absolute bottom-0 w-[100vw]'>
                                <div style={{backgroundImage: `url(${bgFooter})`, backgroundSize: 'cover'}} className='pl-10 pr-10 pb-10 pt-1'>
                                    <div className={'footer-news'}>
                                        <div>
                                            <ul>
                                                <li onClick={() => {
                                                    pageHandler('Berita')
                                                    handleClickTop()
                                                    }} style={{color: 'black'}}>Berita</li>
                                                <li onClick={() => {
                                                    pageHandler('Dagangan')
                                                    handleClickTop()
                                                    }} style={{color: 'black'}}>Toko Dagangan</li>
                                                <li onClick={() => window.location.href ='https://wa.me/+62082312348339'} style={{color: 'black'}}>Hubungi Kami</li>
                                            </ul>
                                        </div>
                                        <div>
                                            <img src={konsersium} className='konsersium'/>
                                        </div>
                                    </div>
                                    <div className='copyright mt-5'>
                                        <p className={isLargeDevice ? 'copy-text-mobile' : 'copy-text'} style={{color: 'black'}}>© Copyright 2023 Semesta Bergoyang All Right Reserved</p>
                                        <div className='flex flex-row absolute right-12'>
                                            <img className='logo-footer' src={spotify} style={{marginRight: 30}} onClick={() => window.location.href = 'https://open.spotify.com/user/31uu56xrso72kbwzioediignpjo4?si=4c80d242005c4dbb&nd=1&dlsi=6d44c105d2154c23'}/>
                                            <img className='logo-footer' src={tiktok} style={{marginRight: 30}} onClick={() => window.location.href = 'https://www.tiktok.com/@semestabergoyang'}/>
                                            <img className='logo-footer' src={youtube}style={{marginRight: 30}} onClick={() => window.location.href = 'https://www.youtube.com/@semestabergoyang'}/>
                                            <img className='logo-footer' src={ig} onClick={() => window.location.href = 'https://www.instagram.com/semestabergoyang/'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Parallax>
            </div>
        </div>
    )
}
