import React from 'react'
import '../style/Slider.css'

export default function Carousel() {
    const photo1 = require('../assets/img/tentang/ig1.png')
    const photo2 = require('../assets/img/tentang/ig2.png')
    const photo3 = require('../assets/img/tentang/ig3.png')
    
    
    const slideImages = [
        {
            id: 1,
            img: photo1
        },
        {
            id: 2,
            img: photo2
        },
        {
            id: 3,
            img: photo3
        }
    ];
    

    return (
        <div className="w-full flex flex-row overflow-x-scroll px-4 slide">
            {slideImages.map((val) => {
                return (
                    <div key={val.id} className="w-40 h-full flex-shrink-0 mr-1">
                        <img src={val.img} alt='img-map' className={'h-40'} onClick={() => window.location.href = 'https://www.instagram.com/semestabergoyang/'} />
                    </div>
                );
            })}
        </div>
    );
}